import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../settings';

const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const volumecontrol = `${settings.IMAGES_BASE_URL}/images/assets/bmw-volume.png`;
class HomeVideoBackground extends React.Component {
  state = {
    width: '',
    volume: false
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  scrollToRef = () => {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  };


  volumeOn = () => {
    this.setState({
      volume: !this.state.volume
    })
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 767;
    const showHeader = (!isMobile || (isMobile && !this.props.hideMobileHeader));

    return (
      <React.Fragment>
        <div>
          {showHeader && (
            <div className="hero__header video-background-header">
              {this.props.topLeftChildren && (
                <div className="hero__header_topleft">
                  {this.props.topLeftChildren}
                </div>
              )}
            </div>
          )}
          {isMobile ? (
            <div className="mobile-image-container">
              <img 
                fetchpriority="high"
                decoding="async"
                loading="eager"
                srcSet={this.props.srcSet}
                sizes={this.props.sizes}
                src={this.props.poster}
                width="100%"
                height="100%"
                style={{ aspectRatio: '16/9' }}
                alt="Video Poster"
              />
            </div>
          ) : (
            <div className="homevideo-background">
              <video
                preload="metadata"
                fetchpriority="high"
                id="homevideo-background"
                playsInline
                autoPlay
                muted={!this.state.volume}
                loop
                poster={this.props.poster}
                width="100%"
                height="100%"
                style={{ 
                  aspectRatio: '16/9',
                  contentVisibility: 'auto',
                  containIntrinsicSize: '100vw 56.25vw'
                }}
              >
                <source 
                  src={this.props.url} 
                  type="video/mp4"
                  media="(min-width: 768px)" 
                />
                {this.props.captions && (
                  <track 
                    src={this.props.captions} 
                    label="English" 
                    kind="captions" 
                    srcLang="en" 
                    default 
                  />
                )}
              </video>
              <div className="homevideo-background__content">
                {this.props.image && (
                  <LazyLoadImage 
                    effect="blur" 
                    src={this.props.image} 
                    alt={this.props.imageAlt}
                    width="100%"
                    height="100%" 
                    style={{ aspectRatio: '16/9' }}
                  />
                )}
                <h1 className="homevideo-background__header-text">{this.props.headerTitle}</h1>
                <button className="arrow" aria-label="click here to scroll down" onClick={this.scrollToRef} onKeyDown={this.scrollToRef}>
                  <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
                </button>
                {!this.props.noAudio && <button className="volume__control" onClick={this.volumeOn}><img fetchpriority="high" src={volumecontrol} alt="Volume on" /> </button>}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default HomeVideoBackground;
